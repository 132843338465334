import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import styles from "./review-passenger.module.scss";
import FlightCard from "../flight-card";
import DateSelectionWidget from "../date-selection-widget";
import { Accordion, Form, InputGroup } from "react-bootstrap";
import TGCheckBox from "../../../../../shared/tg-checkbox";
import { getTierImg, isValidEmail } from "../../../../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import TGInput from "../../../../../shared/tg-input";
// import countryData from "../../../../public/json/country-nationality-code-flag.json";
import TGSelection from "../../../../../shared/tg-selection";
import TGButton from "../../../../../shared/tg-button";
import { isValid } from "date-fns";
import TGIcon from "../../../../../shared/tg-icon";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  airAwardBookingRequest,
  airAwardBookingReset,
} from "../../../slice/airAwardBookingSlice";
import { RootState } from "../../../slice/RootReducer";
import {
  airAwardPnrRequest,
  airAwardPnrReset,
} from "../../../slice/airAwardPnrSlice";
import {
  airAwardPriceRequest,
  airAwardPriceReset,
} from "../../../slice/airAwardPriceSlice";
import {
  airAwardTstRequest,
  airAwardTstReset,
} from "../../../slice/airAwardTstSlice";
import {
  airAwardReviewPnrRequest,
  airAwardReviewPnrReset,
} from "../../../slice/airAwardReviewPnrSlice";
import Redemption from "../index";
import FlightDetails from "../flight-card/flight-card-mobile/flight-details-model";
import { disable_continue_btn } from "../../../slice/flightInfoSlice";
import { fetchCountryRequest } from "../../../slice/fetchProfileSlice";
import { resetPersistStore } from "../../../../../store";
import { fetchNomineeRequest } from "../../../slice/fetchNomineeSlice";

const ReviewPassenger: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  const dispatch = useDispatch();
  const [passengers, setPassengers] = useState<any>([]);
  const [tripDetails, setTripDetails] = useState([]);
  const [showFlightDetailsPopup, setShowDetailsFlightPopup] = useState(false);
  const [flightDetailList, setFlightDetailList] = useState({});
  const [countryData, setCountryData] = useState([]);

  const paxInfo = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.redemptionBookingData
  );
  const selectedFlightData = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo
  );
  console.log("selectedFlightData", selectedFlightData);
  const airAwardBookingData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardBooking
  );
  const airAwardPnrData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardPnr
  );
  const airAwardPriceData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardPrice
  );
  const airAwardReviewPnrData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardReviewPnr
  );
  const airAwardTstData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardTst
  );

  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile?.profileData
  );
  const nomineeDetails = useSelector(
    (state: RootState) => state?.osciReducer?.nominee?.nomineeData
  );

  const countryResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.countryData
  );
  console.log(
    "airAwardBookingData",
    airAwardBookingData,
    airAwardPnrData,
    airAwardPriceData,
    airAwardTstData
  );
  const navigate = useNavigate();
  const [totalSelectedMiles, setTotalSelectedMiles] = useState(Number);

  const [getTotalSelectedMiles, setGetTotalSelectedMiles] = useState<
    any | null
  >(null);

  useEffect(() => {
    const miles = window.localStorage.getItem("totalSelectedMiles");
    setGetTotalSelectedMiles(miles);
    if (!nomineeDetails?.nomineeDetail?.length) {
      dispatch(fetchNomineeRequest(profileDetails?.memberID));
    }
  }, []);

  const [error, setError] = useState({
    isContactError: false,
    contact: "",
    isEmailError: false,
    email: "",
    isNomineeError: false,
    nomineeErrorMessage: "",
  });
  const [inputData, setInputData] = useState<any>({
    contact: "",
    country: null,
    email: "",
  });
  const [dialCode, setDialCode] = useState("");
  console.log("inputData", error);

  useEffect(() => {
    dispatch(fetchCountryRequest());
  }, [dispatch]);

  useEffect(() => {
    setCountryData(countryResponse || []);
  }, [countryResponse]);

  const getDefaultCountryCode = () => {
    const userCountryCode = `${profileDetails?.phone?.[0]?.countryCode}`;
    const filteredObj =
      countryData.length > 0 &&
      countryData?.filter(
        (x: any) => x.dial_code === "+" + userCountryCode
      )?.[0];
    return filteredObj;
  };

  const clearData = () => {
    dispatch(airAwardBookingReset());
    dispatch(airAwardPnrReset());
    dispatch(airAwardPriceReset());
    dispatch(airAwardTstReset());
    dispatch(airAwardReviewPnrReset());
    resetPersistStore();
  };

  useEffect(() => {
    if (
      airAwardBookingData?.airAwardBookingInfo?.data?.success &&
      airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId &&
      !window?.navigation?.canGoForward
    ) {
      const raw: any = {
        bookingRefId:
          airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
      };
      dispatch(airAwardPnrRequest(raw));
    } else if (Object.keys(airAwardBookingData?.error)?.length) {
      setError({
        ...error,
        nomineeErrorMessage: "Something Went Wrong",
        isNomineeError: true,
      });
    }
  }, [
    airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
    airAwardBookingData?.error,
  ]);

  useEffect(() => {
    if (
      airAwardPnrData?.airAwardPnrInfo?.data?.success &&
      airAwardPnrData?.airAwardPnrInfo?.data?.session &&
      !window?.navigation?.canGoForward
    ) {
      const raw: any = {
        bookingRefId:
          airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
        session: airAwardPnrData?.airAwardPnrInfo?.data?.session,
      };
      dispatch(airAwardPriceRequest(raw));
    } else if (airAwardPnrData?.error?.data?.data?.[0]?.errorMsg?.length) {
      setError({
        ...error,
        nomineeErrorMessage:
          airAwardPnrData?.error?.data?.data?.[0]?.errorMsg?.[0],
        isNomineeError: true,
      });
    }
  }, [
    airAwardPnrData?.airAwardPnrInfo?.data?.session?.sessionId,
    airAwardPnrData?.error,
  ]);

  useEffect(() => {
    if (
      airAwardPriceData?.airAwardPriceInfo?.data?.success &&
      airAwardPriceData?.airAwardPriceInfo?.data?.session &&
      !window?.navigation?.canGoForward
    ) {
      const raw: any = {
        bookingRefId:
          airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
        session: airAwardPriceData?.airAwardPriceInfo?.data?.session,
      };
      dispatch(airAwardTstRequest(raw));
    } else if (Object.keys(airAwardPriceData?.error)?.length) {
      setError({
        ...error,
        nomineeErrorMessage: "Something Went Wrong",
        isNomineeError: true,
      });
    }
  }, [
    airAwardPriceData?.airAwardPriceInfo?.data?.session?.sessionId,
    airAwardPriceData?.error,
  ]);

  useEffect(() => {
    if (
      airAwardTstData?.airAwardTstInfo?.data?.success &&
      airAwardTstData?.airAwardTstInfo?.data?.session &&
      !window?.navigation?.canGoForward
    ) {
      const raw: any = {
        bookingRefId:
          airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
        session: airAwardTstData?.airAwardTstInfo?.data?.session,
      };
      dispatch(airAwardReviewPnrRequest(raw));
    } else if (Object.keys(airAwardTstData?.error)?.length) {
      setError({
        ...error,
        nomineeErrorMessage: "Something Went Wrong",
        isNomineeError: true,
      });
    }
  }, [
    airAwardTstData?.airAwardTstInfo?.data?.session?.sessionId,
    airAwardTstData?.error,
  ]);

  useEffect(() => {
    if (
      airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.success &&
      airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
    ) {
      sessionStorage?.removeItem("entry");
      navigate(`/${i18n?.language}/redemption/review`);
    }
  }, [airAwardReviewPnrData?.airAwardReviewPnrInfo]);

  useEffect(() => {
    const result = [
      {
        sequence: "0",
        isRopMember: true,
        firstName: profileDetails?.firstName,
        middleName: profileDetails?.middleName,
        lastName: profileDetails?.lastName,
        passportNo: "-",
        issueDate: "-",
        expiryDate: "-",
        checked: true,
      },
    ];
    const nomineeArr =
      nomineeDetails?.nomineeDetail?.map((x: any) => {
        return {
          ...x,
          checked: false,
        };
      }) || [];
    setPassengers([...result, ...nomineeArr]);
    setInputData({
      ...inputData,
      email: profileDetails?.email,
      contact:
        profileDetails?.phone?.[0]?.phoneNumber?.replace(
          /(\d{2})(\d{3})(\d{3})/,
          "$1-$2-$3"
        ) || "",
      country: getDefaultCountryCode() || null,
    });
  }, [nomineeDetails, profileDetails, countryData]);

  useEffect(() => {
    if (paxInfo?.[0]?.journeyType?.toLowerCase() === "return") {
      let tripData: any = [];
      selectedFlightData &&
        selectedFlightData?.selectedFlightList?.[0]?.departure?.map(
          (x: any) => {
            tripData.push({
              tripType: "Depart",
              tripRoute: `${x?.selectedFlight?.departure} - ${x?.selectedFlight?.arrival}`,
              tripDate: `${moment(
                x?.selectedFlight?.departureDate,
                "DDMMYY"
              ).format("ddd, DD MMM YYYY")}`,
              tripTime: `${moment(
                x?.selectedFlight?.departureTime,
                "HHmm"
              ).format("hh:mm")} - ${moment(
                x?.selectedFlight?.arrivalTime,
                "HHmm"
              ).format("hh:mm")}`,
              selectedFlight: x?.selectedFlight,
              bookingClass: x?.bookingClass,
              selectedFlightDetails: x,
            });
          }
        );
      selectedFlightData &&
        selectedFlightData?.selectedFlightList?.[1]?.arrival?.map((x: any) => {
          tripData.push({
            tripType: "Return",
            tripRoute: `${x?.selectedFlight?.departure} - ${x?.selectedFlight?.arrival}`,
            tripDate: `${moment(
              x?.selectedFlight?.arrivalDate,
              "DDMMYY"
            ).format("ddd, DD MMM YYYY")}`,
            tripTime: `${moment(
              x?.selectedFlight?.departureTime,
              "HHmm"
            ).format("hh:mm")} - ${moment(
              x?.selectedFlight?.arrivalTime,
              "HHmm"
            ).format("hh:mm")}`,
            selectedFlight: x?.selectedFlight,
            bookingClass: x?.bookingClass,
            selectedFlightDetails: x,
          });
        });
      setTripDetails(tripData);
      let selectedDepart =
        selectedFlightData?.selectedFlightList?.[0]?.departure;
      let selectedArrival =
        selectedFlightData?.selectedFlightList?.[1]?.arrival;
      let departureCount = 0;
      let arrivalCount = 0;
      for (let i = 0; i < selectedDepart?.length; i++) {
        departureCount =
          departureCount + Number(selectedDepart[i].bookingClass.miles);
      }
      for (let i = 0; i < selectedArrival?.length; i++) {
        arrivalCount =
          arrivalCount + Number(selectedArrival[i].bookingClass.miles);
      }
      let totalMilesArrivalAndDepart = departureCount + arrivalCount;
      setTotalSelectedMiles(totalMilesArrivalAndDepart);
    } else {
      let tripData: any = [];
      selectedFlightData &&
        selectedFlightData?.selectedFlightList?.map((x: any) => {
          tripData.push({
            tripType: "Depart",
            tripRoute: `${x?.selectedFlight?.departure} - ${x?.selectedFlight?.arrival}`,
            tripDate: `${moment(
              x?.selectedFlight?.departureDate,
              "DDMMYY"
            ).format("ddd, DD MMM YYYY")}`,
            tripTime: `${moment(
              x?.selectedFlight?.departureTime,
              "HHmm"
            ).format("hh:mm")} - ${moment(
              x?.selectedFlight?.arrivalTime,
              "HHmm"
            ).format("hh:mm")}`,
            selectedFlight: x?.selectedFlight,
            bookingClass: x?.bookingClass,
            selectedFlightDetails: x,
          });
        });
      setTripDetails(tripData);
      let totalMiles = 0;
      selectedFlightData?.selectedFlightList?.map((item: any, index: any) => {
        totalMiles =
          item?.bookingClass?.miles !== undefined
            ? totalMiles + Number(item?.bookingClass?.miles)
            : totalMiles + 0;
      });
      setTotalSelectedMiles(totalMiles);
    }
  }, [paxInfo, selectedFlightData, selectedFlightData?.selectedFlightList]);

  const getMemberTierImg = () => {
    const currentTier = profileDetails?.privilegeCard?.toLowerCase();
    return `../${getTierImg(currentTier)}`;
  };

  const getCurrentPaxCount = () => {
    const checkedCount = passengers?.filter((x: any) => x.checked)?.length;
    return checkedCount;
  };

  const handleChange = (x: any) => {
    setError({ ...error, nomineeErrorMessage: "", isNomineeError: false });
    setPassengers(
      passengers?.map((pax: any) =>
        pax.nomineeId === x.nomineeId
          ? {
              ...pax,
              checked:
                getCurrentPaxCount() >= paxInfo?.[0]?.pax
                  ? false
                  : !pax.checked,
            }
          : pax
      )
    );
  };

  const handleFieldChange = (e: any, label: string) => {
    setDialCode("");
    setInputData({
      ...inputData,
      [label]:
        label === "contact"
          ? e?.target?.value?.replace(
              /(\d{2})(\d{3})(\d{4})?(\d{5})/,
              "$1-$2-$3$4"
            )
          : e.target.value,
    });
    let flag = "";
    switch (label) {
      case "contact":
        flag = "isContactError";
        break;
      case "email":
        flag = "isEmailError";
        break;
    }
    setError({ ...error, [label]: "", [flag]: false });
  };

  const renderCountryCode = () => {
    let result =
      countryData.length > 0 &&
      countryData?.map((x: any) => {
        return {
          ...x,
          listName: x?.dial_code,
          src: x?.image,
        };
      });
    if (dialCode) {
      result = result?.filter(
        (x: any) =>
          x?.dial_code?.includes(dialCode) ||
          x?.name?.toLowerCase()?.includes(dialCode?.toLowerCase())
      );
    }
    return result;
  };

  const handleProfileRedirection = () => {
    navigate(`/${i18n.language}/myprofile`);
  };

  const handleBack = () => {
    dispatch(disable_continue_btn(false));
    navigate(`/${i18n?.language}/redemption/selectflight`, { replace: true });
  };

  const handleEdit = () => {
    dispatch(disable_continue_btn(false));
    navigate(`/${i18n?.language}/redemption/selectflight`);
  };

  const handleContinue = () => {
    clearData();
    let contactErr = "";
    let emailErr = "";
    let nomineeErr = "";
    const checkedPaxData = passengers?.filter((y: any) => y?.checked);
    console.log("travellerInfo", checkedPaxData);
    if (!inputData?.contact || !inputData?.country) {
      contactErr = t("profile_view_contact_err");
    }
    if (inputData?.email === "") {
      emailErr = "Email is required";
    } else if (!isValidEmail(inputData?.email)) {
      emailErr = t("reset_invalid_message");
    }
    //
    if (getCurrentPaxCount() > paxInfo?.[0]?.pax) {
      nomineeErr =
        "Too many passengers nominated. Maximum 4 passengers per trip.";
    }
    if (checkedPaxData?.length < Number(paxInfo?.[0]?.pax)) {
      nomineeErr = `Please Select ${paxInfo?.[0]?.pax} Passengers`;
    }
    setError({
      ...error,
      isContactError: contactErr !== "",
      contact: contactErr,
      isEmailError: emailErr !== "",
      email: emailErr,
      isNomineeError: nomineeErr !== "",
      nomineeErrorMessage: nomineeErr,
    });
    if (contactErr === "" && emailErr === "" && nomineeErr === "") {
      let departureItenaryData = [];
      let arrivalItenaryData = [];
      if (paxInfo?.[0]?.journeyType?.toLowerCase() === "return") {
        departureItenaryData =
          selectedFlightData?.selectedFlightList?.[0]?.departure?.map(
            (x: any) => {
              return {
                flightNum: x?.selectedFlight?.flightNum,
                marketCarrier: x?.selectedFlight?.mc,
                departure: x?.selectedFlight?.departure,
                arrival: x?.selectedFlight?.arrival,
                flightDate:
                  moment(x?.airportLocations?.date)?.format("YYYY-MM-DD") +
                  "T00:00:00",
                bookingClass: x?.bookingClass?.bookingClass,
              };
            }
          );
        arrivalItenaryData =
          selectedFlightData?.selectedFlightList?.[1]?.arrival?.map(
            (x: any) => {
              return {
                flightNum: x?.selectedFlight?.flightNum,
                marketCarrier: x?.selectedFlight?.mc,
                departure: x?.selectedFlight?.departure,
                arrival: x?.selectedFlight?.arrival,
                flightDate:
                  moment(x?.airportLocations?.date)?.format("YYYY-MM-DD") +
                  "T00:00:00",
                bookingClass: x?.bookingClass?.bookingClass,
              };
            }
          );
      } else {
        departureItenaryData = selectedFlightData?.selectedFlightList?.map(
          (x: any) => {
            return {
              flightNum: x?.selectedFlight?.flightNum,
              marketCarrier: x?.selectedFlight?.mc,
              departure: x?.selectedFlight?.departure,
              arrival: x?.selectedFlight?.arrival,
              flightDate:
                moment(x?.airportLocations?.date)?.format("YYYY-MM-DD") +
                "T00:00:00",
              bookingClass: x?.bookingClass?.bookingClass,
            };
          }
        );
      }
      console.log(
        "itineraryList",
        departureItenaryData,
        arrivalItenaryData,
        selectedFlightData?.selectedFlightList?.[0]
      );
      const travellerInfo = passengers
        ?.filter((y: any) => y?.checked && y?.nomineeId)
        ?.map((z: any) => {
          return {
            paxId: z?.nomineeId,
          };
        });
      const req: any = {
        emailId: inputData?.email,
        countryCd: inputData?.country?.dial_code?.replace("+", ""),
        areaCd: "00",
        phone: inputData?.contact?.replaceAll("-", ""),
        memberTravelling: passengers?.[0]?.checked,
        ruleSetId: selectedFlightData?.selectedRuleSet,
        tripType:
          paxInfo?.[0]?.journeyType?.toLowerCase() === "return"
            ? "R"
            : paxInfo?.[0]?.journeyType?.toLowerCase() === "one-way"
            ? "O"
            : "M",
        nominees: travellerInfo,
        itineraryList:
          paxInfo?.[0]?.journeyType?.toLowerCase() === "return"
            ? [...departureItenaryData, ...arrivalItenaryData]
            : [...departureItenaryData],
      };
      dispatch(airAwardBookingRequest(req));
    }
  };

  const showFlightDetails = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    responseData: any
  ) => {
    setShowDetailsFlightPopup(true);
    const obj = {
      responseData,
    };
    setFlightDetailList(obj);
    event.stopPropagation();
  };

  const handleClose = () => {
    setShowDetailsFlightPopup(false);
  };

  return (
    <>
      <Redemption />
      <div
        className={`${styles["review-passenger-main-wrapper"]} global-content-padding`}
      >
        {isDesktopView && (
          <div className={styles["review-passenger-heading"]}>
            {t("label_review_passenger")}
          </div>
        )}
        <div className={`d-flex gap-4 ${!isDesktopView && "flex-column"}`}>
          {isMobile && (
            <div className={styles.displaySelectedCard}>
              {tripDetails?.map((x: any) => (
                <div className={styles.summaryCard}>
                  <div>
                    <div className={styles.triptypeAndClass}>
                      <span className={styles.tripTypeVal}>{x?.tripType}</span>
                      <span className={styles.classlistVal}>
                        {x?.selectedFlight?.bookingClass?.classDesc}
                      </span>
                    </div>
                    <div
                      className={styles["mobile-flight-summary-card-section"]}
                    >
                      <div className={styles["mobile-flight-section"]}>
                        <div
                          className={
                            styles["mobile-flight-summary-date-section"]
                          }
                        >
                          <div>
                            {moment(
                              x?.selectedFlight?.departureDate,
                              "DDMMYY"
                            ).format("ddd, DD MMM YYYY")}
                          </div>
                          <div>
                            {moment(
                              x?.selectedFlight?.arrivalDate,
                              "DDMMYY"
                            ).format("ddd, DD MMM YYYY")}
                          </div>
                        </div>
                        <div
                          className={
                            styles["mobile-flight-summary-travel-time-details"]
                          }
                        >
                          <div
                            className={styles["mob-flight-departure-details"]}
                          >
                            <div>
                              {moment(
                                x?.selectedFlight?.departureTime,
                                "HHmm"
                              ).format("hh:mm")}
                            </div>
                            <div>{x?.selectedFlight?.departure}</div>
                          </div>
                          <div
                            className={styles["mob-flight-duration-details"]}
                          >
                            <div>
                              {moment
                                .utc(
                                  moment(
                                    x?.selectedFlight?.arrivalTime,
                                    "HHmm"
                                  ).diff(
                                    moment(
                                      x?.selectedFlight?.departureTime,
                                      "HHmm"
                                    )
                                  )
                                )
                                .format("h [h] m [min]")}
                            </div>
                            <div>
                              <div></div>
                              <div>
                                <TGIcon
                                  icon="plane-icon"
                                  fillColor=""
                                  size=""
                                />
                              </div>
                              <div></div>
                            </div>
                            <div>{t("label_nonstop")}</div>
                          </div>
                          <div className={styles["mob-flight-arrival-details"]}>
                            <div>
                              {moment(
                                x?.selectedFlight?.arrivalTime,
                                "HHmm"
                              ).format("hh:mm")}
                            </div>
                            <div>{x?.selectedFlight?.arrival}</div>
                          </div>
                        </div>
                        <div className={styles["mob-flight-summary-mileage"]}>
                          {x?.bookingClass?.classDesc}
                          {/* {" - "}
                          {x?.bookingClass?.miles}{" "}
                          {t("label_redemption_header_miles")} */}
                        </div>
                      </div>
                      <div className={styles["mobile-flight-button-section"]}>
                        <div className={styles["mobile-flight-action-section"]}>
                          <div
                            className={styles["mobile-flight-change"]}
                            onClick={() => handleEdit()}
                          >
                            {t("labe_change_flight")}
                          </div>

                          <div
                            className={styles["mobile-flight-details"]}
                            onClick={(e) =>
                              showFlightDetails(e, x?.selectedFlightDetails)
                            }
                          >
                            {t("label_show_details")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {showFlightDetailsPopup && (
            <FlightDetails
              hidePanel={handleClose}
              flightDetailsSummary={flightDetailList}
            />
          )}
          <div
            className={`${styles["review-passenger-wrapper"]} ${styles["review-passenger-container"]}`}
          >
            <div className={styles["review-passenger-sub-heading"]}>
              {`Select Passengers (${getCurrentPaxCount()}/${
                paxInfo?.[0]?.pax
              })`}
            </div>
            <span className={styles["review-passenger-desc"]}>
              {t("label_to_edit_nominee_info")}
            </span>
            <span
              className={styles["review-passenger-nominee-link"]}
              onClick={handleProfileRedirection}
            >
              {" "}
              {t("label_profile_with_dot")}
            </span>
            {passengers?.map((x: any, index: number) => {
              return (
                <Accordion
                  defaultActiveKey="0"
                  className={`${styles["review-passenger-accordian"]} ${
                    x.checked && styles["review-passenger-accordian-checked"]
                  }`}
                  onClick={(e) => handleChange(x, index)}
                  key={index}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      className={styles["review-passenger-accordian-header"]}
                    >
                      <div
                        className={`${styles["review-passenger-nominee-wrapper"]} d-flex flex-column`}
                      >
                        <div className={styles["review-passenger-checkbox"]}>
                          <TGCheckBox
                            label={
                              x.firstName +
                                " " +
                                (x.middleName ?? "") +
                                " " +
                                x.lastName ?? ""
                            }
                            checked={x.checked}
                            className=""
                            customLabel=""
                            onChange={(e) => {}}
                            disabled={
                              !x.checked &&
                              getCurrentPaxCount() >= paxInfo?.[0]?.pax
                            }
                          />
                        </div>
                        {x?.isRopMember ? (
                          <div className="d-flex align-items-center gap-2">
                            {getMemberTierImg() && (
                              <img
                                src={getMemberTierImg()}
                                height={"24px"}
                                width={"48px"}
                                alt="tier"
                              />
                            )}
                            <div className={styles["rop_member_id"]}>
                              {profileDetails?.memberID}
                            </div>
                            <div className={styles["rop_line"]}>|</div>
                            <div className={styles["rop_miles"]}>
                              {profileDetails?.remainingMiles}
                              {profileDetails?.remainingMiles && " Miles"}
                            </div>
                          </div>
                        ) : (
                          <div className={styles["review-passengers-nominee"]}>
                            {t("profile_nominee")}{" "}
                            {`${x.childFlag ? "(Child)" : ""}`}
                          </div>
                        )}
                      </div>
                    </Accordion.Header>
                    {/* {x?.isRopMember && (
                      <Accordion.Body>
                        <div className="d-flex justify-content-between gap-1">
                          <div className="d-flex flex-column">
                            <div className={styles["review-passenger-info"]}>
                              {t("label_passport_nominee")}
                            </div>
                            <div
                              className={styles["review-passenger-info-desc"]}
                            >
                              -
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className={styles["review-passenger-info"]}>
                              {t("label_date_of_issue")}
                            </div>
                            <div
                              className={styles["review-passenger-info-desc"]}
                            >
                              -
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className={styles["review-passenger-info"]}>
                              {t("label_date_of_expiry")}
                            </div>
                            <div
                              className={styles["review-passenger-info-desc"]}
                            >
                              -
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    )} */}
                  </Accordion.Item>
                </Accordion>
              );
            })}
            {error?.isNomineeError && (
              <div className={styles.errorContainer}>
                <TGIcon icon="error-icon" fillColor="" size="18" />
                <div>
                  <div className={styles.errorLabel}>
                    {error?.nomineeErrorMessage}
                  </div>
                </div>
              </div>
            )}
          </div>
          {isDesktopView && (
            <div
              className={`${styles["review-passenger-wrapper"]} ${styles["review-passenger-sidebar-container"]}`}
            >
              {tripDetails?.map((x: any) => (
                <div
                  className={`${styles["review-passenger-sidebar-wrapper"]} d-flex flex-column`}
                >
                  <div className="d-flex justify-content-between">
                    <div
                      className={styles["review-passenger-sidebar-trip-type"]}
                    >
                      {x?.tripType}
                    </div>
                    <div
                      className={styles["review-passenger-sidebar-edit"]}
                      onClick={handleEdit}
                    >
                      {t("label_change")}
                    </div>
                  </div>
                  <div
                    className={styles["review-passenger-sidebar-airport-code"]}
                  >
                    {x?.tripRoute}
                  </div>
                  <div className={styles["review-passenger-sidebar-trip-date"]}>
                    {x?.tripDate}
                  </div>
                  <div className={styles["review-passenger-sidebar-trip-time"]}>
                    {x?.tripTime}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-between">
                <div className={styles["review-passenger-pax-info"]}>
                  {paxInfo?.[0]?.pax} {t("label_book_widget_passengers")}
                </div>
                <div>
                  <div className="d-flex gap-1">
                    <div className={styles["review-passenger-miles-info"]}>
                      {getTotalSelectedMiles}
                    </div>
                    <div className={styles["review-passenger-miles-text"]}>
                      {t("label_redemption_header_miles")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`mt-4 mb-4 ${styles["review-passenger-wrapper"]} ${styles["review-passenger-custom-wrapper"]}`}
        >
          <div className={styles["review-passenger-sub-heading"]}>
            {t("label_contact_information")}
          </div>
          <div className={styles.contactContainer}>
            <Form.Group className={styles["review-passenger-country-wrapper"]}>
              <InputGroup>
                <TGSelection
                  label={t("profile_view_contact")}
                  isLoading={false}
                  listData={renderCountryCode()}
                  onSelect={(e, selectedObj) =>
                    handleFieldChange(
                      { target: { value: selectedObj } },
                      "country"
                    )
                  }
                  onChange={(e: any) => setDialCode(e?.target?.value)}
                  isError={error?.isContactError}
                  errorLabel={error?.contact}
                  defaultObj={{
                    value: getDefaultCountryCode()?.dial_code || "",
                    image: getDefaultCountryCode()?.image
                      ? getDefaultCountryCode()?.image
                      : "",
                  }}
                  offcanvasClass={styles["tg-selection-review-pax-offcanvas"]}
                />
                <TGInput
                  isEnroll={true}
                  value={inputData.contact}
                  name="contactNumber"
                  onChange={(e) => handleFieldChange(e, "contact")}
                  // isError={!!errors.contactNumber}
                  customClassName={styles["custom-input-container"]}
                  tertiaryCustomClassname={styles.borderContact}
                  placeholder="00-000-000"
                  isError={error?.isContactError}
                  errorLabel={""}
                  value={inputData?.contact}
                />
              </InputGroup>
            </Form.Group>
            <TGInput
              isEnroll={true}
              label={t("label_reset_email")}
              name="email"
              onChange={(e) => handleFieldChange(e, "email")}
              placeholder="Example: THAI@gmail.com"
              isError={error?.isEmailError}
              errorLabel={error?.email}
              value={inputData?.email}
            />
          </div>
        </div>
        <div
          className={`d-flex gap-3 justify-content-end ${
            !isDesktopView && "flex-column-reverse"
          }`}
        >
          <TGButton
            label="Back"
            variant="secondary"
            customClassName={styles["review-passenger-back-button"]}
            onClick={handleBack}
          />
          <TGButton
            label="Continue"
            variant="primary"
            customClassName={styles["review-passenger-continue-button"]}
            onClick={handleContinue}
          />
        </div>
      </div>
    </>
  );
};

export default ReviewPassenger;
